import { Input, FormFeedback } from 'reactstrap';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

/** Wraps reactstrap's <Input> to make it compatible with react-hook-form. */
export function FormInput({ name, control, controlRules, getOnChangeValue, ...inputProps }) {
  const { 
    field: { ref, onChange, ...restField }, 
    fieldState: { invalid, error },
  } = useController({ name, control, rules: controlRules });

  const onInputChange = (evt) => {
    onChange(getOnChangeValue ? getOnChangeValue(evt) : evt);
  }

  return (
    <>
      <Input
        id={name} 
        invalid={invalid}
        innerRef={ref}
        onChange={onInputChange}
        {...restField} 
        {...inputProps}
      />
      <FormFeedback>{error?.message}</FormFeedback>
    </>
  )
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  controlRules: PropTypes.object,
  getOnChangeValue: PropTypes.func,
}
