import {Component} from "react";
import { Collapse } from "reactstrap";

const TrackingEvent = (props) => {
  let {event_time, message, status, status_display, location} = props.event
  let tracking_date = new Date(event_time).toDateString()
  let tracking_time = new Date(event_time).toLocaleTimeString()

  return (
    <div className={`tracking show-descr  status--${status}`}>
      <div className="tracking__date-time desktop">
        <div className="tracking__date">{tracking_date}</div>
        <div className="tracking__time">{tracking_time}</div>
      </div>
      <div className="tracking__status-icon">&nbsp;</div>
      <div className="tracking__status">
        <div className="tracking__status__name">{status_display}</div>
        <div className="tracking__status__descr country">{`${location?.country || ''} ${location?.state || ''} ${location?.city || ''}`}</div>
        <div className="tracking__status__descr message">{message}</div>
        <div className="tracking__date-time mobile">
          <div className="tracking__date">{tracking_date}</div>
          <div className="tracking__time">{tracking_time}</div>
        </div>
      </div>
    </div>
  )
}


export default class TrackingEventsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_tracking_events_opened: false,
    }
  }

  toggleTrackingList = () => this.setState({
    is_tracking_events_opened: !this.state.is_tracking_events_opened
  })

  renderTrackingEventsList() {
    const shown_events = this.props.tracking_events.slice(0, 3)
    const hidden_events = this.props.tracking_events.slice(3)

    const shown_tracking_events_list = shown_events.map(item => {
        return (
          <TrackingEvent key={`${item.message}-${item.event_time}`} event={item}/>
        )
      })

    const hidden_tracking_events_list = hidden_events.map(item => {
        return (
          <TrackingEvent key={`${item.message}-${item.event_time}`} event={item}/>
        )
      })

    const hidden_tracking_events_list_block = () => {
      return (
        <div>
          <Collapse isOpen={this.state.is_tracking_events_opened}>{hidden_tracking_events_list}</Collapse>
          <button
            onClick={this.toggleTrackingList}
            className={`tracking-expand ${this.state.is_tracking_events_opened ? 'expanded' : ''} status--${this.props.fulfilment?.tracking_status || 'disabled'}`}
            >
            { this.state.is_tracking_events_opened ?
              <span>Less</span> :
              <span>More</span>
            }
            <span>tracking history</span>
          </button>
        </div>
      )
    }

    const full_tracking_event_list = () => {
      return (
        <div className="tracking-container-inner">
          {
            shown_tracking_events_list
          }
          {
            hidden_tracking_events_list.length > 0 && hidden_tracking_events_list_block()
          }
        </div>
      )
    }

    return full_tracking_event_list();
  }

  render() {
    return (
      <div className="card">
        <div className="card__body">
          <div className="tracking-container">
            {
              this.props.tracking_events && this.renderTrackingEventsList()
            }
          </div>
        </div>
      </div>
    )
  }
}