import React, {Component} from "react";
import { Helmet } from 'react-helmet'
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ClientThemeContext, themes } from "./constants/themes";
import { ToastsContext } from "./utils/toasts.context";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { Empty } from "./components/Empty";
import { ToastsContainer } from "./components/ToastsContainer";

import { HomePage } from "./pages/HomePage";
import { InsurancePage } from "./pages/InsurancePage";

const TOAST_DISAPPER_TIMEOUT_MS = 5000;

// TODO: rework it using functional components
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      theme: themes.default,
      toasts: [],
    }
  }

  updateTheme(preferences) {
    const theme = this.getThemeByPreferences(preferences);

    if (theme.name !== this.state.theme.name) {
      this.updateCssVariablesByTheme(theme);  
      this.setState({ theme });
    }
  }

  getThemeByPreferences(preferences) {
    const clientName = preferences?.name;

    if (!clientName) {
      return themes.default;
    }

    if (!themes[clientName]) {
      themes[clientName] = { ...preferences };
    }

    return themes[clientName];
  }

  updateCssVariablesByTheme(theme) {
    document.documentElement.style.setProperty("--client-color", theme.config.theme_color);
    document.documentElement.style.setProperty("--client-font-color", theme.config.font_color);
  }

  /** 
   * Show new toast and hide it after timeout.
   * 
   * @param {import('./utils/toasts.context').ToastPayload} payload */
  showToast(toast) {
    this.setState((prev) => {
      const idx = prev.toasts.length;

      setTimeout(() => this.removeToastByIdx(idx), TOAST_DISAPPER_TIMEOUT_MS);

      return { toasts: prev.toasts.concat(toast) }
    });
  }

  removeToastByIdx(idx) {
    this.setState((prev) => ({ toasts: prev.toasts.toSpliced(idx, 1) }));
  }

  render() {
    const clientGtmId = this.state?.theme?.config?.google_tag_manager_id;

    return (
      <BrowserRouter>
        <ToastsContext.Provider value={{ showToast: this.showToast.bind(this) }}>
          <ClientThemeContext.Provider value={{ theme: this.state.theme, updateTheme: this.updateTheme.bind(this) }}>
            <Helmet>
              <meta property="og:site_name" content={window.location.hostname} />
              <meta property="og:url" content={window.location.origin } />
              <meta property="og:description" content="Check the status of your most recent orders or process a return through our automated Order Tracking and Returns page." />
              <meta property="og:type" content="website" />
              <link rel="icon" href={this.state.theme?.config?.favicon} />
              <script type='text/javascript'>
                {
                  // add google tag manager script if user provide the GTM ID
                  clientGtmId &&
                  `<!-- Google Tag Manager -->
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${clientGtmId}');
                  <!-- End Google Tag Manager -->`
                }
              </script>
            </Helmet>

            <>
              <Header />

              <div className="site-body">
                <Switch>
                  <Route path="/insurance/:id">
                    <InsurancePage />
                  </Route>

                  <Route path="/:trackingNumber">
                    <HomePage />
                  </Route>

                  <Route path="/">
                    <Empty />
                  </Route>
                </Switch>
              </div>

              <Footer />

              <ToastsContainer toasts={this.state.toasts} />
            </>
          </ClientThemeContext.Provider>
        </ToastsContext.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
