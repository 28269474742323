import React, {useCallback, useMemo} from 'react';
import {GoogleMap, Marker, Polyline, useJsApiLoader} from "@react-google-maps/api";
import {pick} from 'lodash';

import iconShip from '../assets/icons/icon-ship.svg';
import iconSource from '../assets/icons/icon-source.svg';
import iconDestination from '../assets/icons/icon-destination.svg';

import {
  MAP_OPTIONS,
  MAP_CONTAINER_STYLE,
  MAP_AIS_ROUTE_OPTIONS,
  MAP_PLAN_ROUTE_OPTIONS
} from "../constants/map";
import { GOOGLE_MAPS_API } from '../constants/index';


class MapHelpers {
  static coordinatesTupleToLatLng(coords) {
    return {lat: coords[1], lng: coords[0]};
  };
}

export const Map = ({ order, vesselTracking }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API,
  });

  const [coordsAis, coordsPlan] = useMemo(() => {
    const paths = pick(vesselTracking, ['ais_route', 'plan_route']);

    return Object.values(paths).map(
      (value) => value.map(MapHelpers.coordinatesTupleToLatLng)
    );
  }, [vesselTracking]);

  const [source, ship, destination] = useMemo(() => {
    return [
      coordsPlan[0],
      coordsAis[coordsAis.length - 1],
      coordsPlan[coordsPlan.length - 1]
    ];
  }, [coordsAis, coordsPlan]);

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
      .extend(source)
      .extend(destination)

    map.fitBounds(bounds);
  }, [source, destination]);

  return isLoaded && (
    <GoogleMap
      zoom={7}
      center={ship}
      options={MAP_OPTIONS}
      mapContainerStyle={MAP_CONTAINER_STYLE}
      mapContainerClassName="map-container"
      onLoad={onMapLoad}
    >
      <Marker position={source} icon={iconSource} />
      <Marker position={destination} icon={iconDestination} />
      <Marker position={ship} icon={iconShip} zIndex={1000} />

      <Polyline path={coordsPlan} options={MAP_PLAN_ROUTE_OPTIONS} />
      <Polyline path={coordsAis} options={MAP_AIS_ROUTE_OPTIONS} />
    </GoogleMap>
  );
}
