import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet'

import { useInsurancePageParams, useInsurancePageData, useTrackingPageData } from '../utils/hooks';
import { checkIfFormShouldBeDisabled } from '../utils/insurance.utils';
import { ClientThemeContext } from '../constants/themes';

import Loader from "../components/Loader";
import { Empty, EmptyWrapper } from '../components/Empty';
import { InsuranceForm } from '../components/InsuranceForm';


export function InsurancePage() {
  const containerRef = useRef(null);

  const [isFormDisabled, setIsFormDisabled] = useState();

  const { theme, updateTheme } = useContext(ClientThemeContext);
  
  const { id, reference, trackingNumber } = useInsurancePageParams();

  const { data: insuranceClaimData, setData: setInsuranceClaimsData, loading: isInsuranceClaimLoading } = useInsurancePageData(id);

  // TODO: load this data in root component (blocked by Home.js)
  const { data: trackingPageData, loading: isTrackingPageLoading } = useTrackingPageData(reference, trackingNumber);

  useEffect(() => {
    if (trackingPageData?.preferences) {
      updateTheme(trackingPageData.preferences);
    }
  }, [trackingPageData, updateTheme]);

  useEffect(() => setIsFormDisabled(checkIfFormShouldBeDisabled(insuranceClaimData)), [insuranceClaimData])

  const pageTitle = useMemo(() => `Claim Request | ${theme.config.company_name || theme.config.name}`, [theme]);

  const order = useMemo(() => trackingPageData?.orders?.[0], [trackingPageData]);
  const fulfillment = useMemo(() => order?.fulfillments?.[0], [order]);

  const isLoading = useMemo(() => isInsuranceClaimLoading || isTrackingPageLoading, [isInsuranceClaimLoading, isTrackingPageLoading])

  if (isLoading) {
    return <Loader />;
  }

  if (!order && !isLoading) {
    return <Empty trackingNumber={reference} trackingNumberParam={trackingNumber} />
  }

  if (!insuranceClaimData && !isLoading) {
    return (
      <EmptyWrapper>
        <div>
          <h2>Whoops, it looks like there is no insurance claim with the specified parameters.</h2>
          <p>Please make sure the URL is correct.</p>
        </div>
      </EmptyWrapper>
    );
  }

  const afterFormSubmit = (updatedClaim) => {
    if (updatedClaim) {
      setInsuranceClaimsData(updatedClaim);
      setIsFormDisabled(checkIfFormShouldBeDisabled(updatedClaim));
    }

    containerRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
      </Helmet>
  
      <div className="main-content">
        <div className="insurance-container" ref={containerRef}>
          <div className="insurance-title">
            Claim Request
          </div>

          <div className="insurance-card">
            <InsuranceForm 
              order={order} 
              fulfillment={fulfillment}
              insuranceClaim={insuranceClaimData}
              disabled={isFormDisabled} 
              afterFormSubmit={afterFormSubmit} 
            />
          </div>
        </div>
      </div>
    </>
  );
}