import { useMemo } from 'react';
import { Col, Form, FormGroup, Label, Alert } from 'reactstrap';
import { useForm } from "react-hook-form"

import { updateInsuranceClaim } from '../utils/api';
import { getErrorMessage } from '../utils/helpers';
import { INSURANCE_CLAIMS_LIST } from '../constants/insurance';

import { FormInput } from './FormInput';
import { FormGroupFile } from './FormGroupFile';
import { ButtonWithSpinner } from './ButtonWithSpinner';

const FILE_KEYS = ['sales_invoice_files', 'correspondence_files', 'images_files', 'other_files'];

export function InsuranceForm({ 
  order, 
  fulfillment, 
  insuranceClaim, 
  disabled, 
  afterFormSubmit 
}) {

  const CURRENT_DATE = useMemo(() => new Date().toISOString().split('T')[0], []);

  const defaultFilesValues = useMemo(() => {
    const filesData = {
      sales_invoice_files: [],
      correspondence_files: [],
      images_files: [],
      other_files: [],
    };

    if (!insuranceClaim) {
      return filesData;
    }

    for (const file of insuranceClaim.files) {
      switch (file.file_type) {
        case 'sales_invoice':
          filesData.sales_invoice_files.push(file);
          break;
        case 'correspondence':
          filesData.correspondence_files.push(file);
          break;
        case 'item_image':
          filesData.images_files.push(file);
          break;
        default:
          filesData.other_files.push(file);
          break;
      }
    }

    return filesData;
  }, [insuranceClaim])

  const defaultValues = useMemo(() => {
    return {
      claim_reason: insuranceClaim?.claim_reason ?? INSURANCE_CLAIMS_LIST[0].value,
      affected_items: insuranceClaim?.affected_items.map((item) => item.order_item) ?? [],
      description: insuranceClaim?.description ?? '',
      date_of_issue: insuranceClaim?.date_of_issue ?? CURRENT_DATE,
      value_of_order: Number(insuranceClaim?.value_of_order ?? 0),
      ...defaultFilesValues,
    };
  }, [CURRENT_DATE, insuranceClaim, defaultFilesValues]);

  const { 
    watch, 
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
    handleSubmit,
  } = useForm({ disabled, defaultValues, mode: 'all' });
  

  const onFormSubmit = async (data) => {
    try {
      // Reset "image_files" and "affected_items" when "lost" claim is selected.
      // We could do this on select event, but that would result in bad UX if claim was changed accidentally.
      const fileKeysSource = data.claim_reason === 'lost'
        ? FILE_KEYS.filter((item) => item !== 'images_files')
        : FILE_KEYS;

      const files = Object.entries(data)
        .filter(([key, _]) => fileKeysSource.includes(key))
        .flatMap(([_, files]) => files);

      const affectedItems = data.claim_reason === 'lost' 
        ? [] 
        : data.affected_items.map((item) => ({ order_item: Number(item) }));

      const payload = { 
        order: order.id,
        customer_name: order.destination_address.full_name,
        claim_reason: data.claim_reason,
        description: data.description,
        date_of_issue: data.date_of_issue,
        value_of_order: Number(data.value_of_order),
        affected_items: affectedItems,
        files: files,
      }

      const { data: updatedClaim } = await updateInsuranceClaim(payload, insuranceClaim.id);

      afterFormSubmit?.(updatedClaim);
    } catch (err) {
      console.error(err);

      // Manually set form error to tell react-hook-form that submit was failed.
      setError("root.server", { type: "server", message: getErrorMessage(err) });

      afterFormSubmit?.();
    }
  }

  const selectedClaimReason = watch('claim_reason');

  return (
    <>
      {isSubmitSuccessful && (
        <Alert color="primary">
          Claim has been successfully submitted. You will be notified when it is reviewed.
        </Alert>
      )}

      {errors?.root?.server && (
        <Alert color="danger">{errors?.root?.server.message}</Alert>
      )}

      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <FormGroup row className="mb-4">
          <Label md={4}>Order reference</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{order.customer_reference || order.reference}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4}>Courier</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{fulfillment.courier_service.service_name}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="customer_name">Customer name</Label>

          <Col md={8} className="d-flex align-items-center">
            <b>{order.destination_address.full_name}</b>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="claim_reason">
            Claim <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              name="claim_reason"
              type="select"
              className="form-control form-select"
              control={control}
              controlRules={{ required: 'Please select the reason for claim.' }}
            >
              {INSURANCE_CLAIMS_LIST.map((item) => (
                <option key={item.value} value={item.value}>{item.text}</option>
              ))}
            </FormInput>
          </Col>
        </FormGroup>

        {/* User must select affected items unless the claim is "lost". */}
        {selectedClaimReason !== 'lost' && (
          <FormGroup row className="mb-4">
            <Label md={4} for="affected_items">
              Select Item(s) for claim <span className="text-danger">*</span>
            </Label>

            <Col md={8}>
              <FormInput 
                name="affected_items" 
                type="select"
                multiple
                className="form-control"
                control={control}
                controlRules={{ required: 'Please select order items for claim.' }}
                getOnChangeValue={(evt) => Array.from(evt.target.options).filter((option) => option.selected).map((option) => option.value)}
              >
                {order.order_items.map((item) => (
                  <option key={item.id} value={item.id}>{item.title}</option>
                ))}
              </FormInput>
            </Col>
          </FormGroup>
        )}

        <FormGroup row className="mb-4">
          <Label md={4} for="description">
            Description of issue <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="description" 
              name="description" 
              type="textarea" 
              rows="3" 
              placeholder="Description of issue. For damaged claims please specify which items were damaged."
              control={control}
              controlRules={{ required: 'Please provide some details about the problem you encountered.' }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="date_of_issue">
            Date of Issue <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="date_of_issue" 
              name="date_of_issue" 
              type="date" 
              placeholder="01.01.1970"
              max={CURRENT_DATE}
              control={control}
              controlRules={{ required: 'Please enter the date the problem occurred.' }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4} for="value_of_order">
            Value of order (USD) <span className="text-danger">*</span>
          </Label>

          <Col md={8}>
            <FormInput 
              id="value_of_order" 
              name="value_of_order" 
              type="number" 
              inputMode="decimal" 
              min="0" 
              step="0.01"
              placeholder="0.00"
              control={control}
              controlRules={{
                required: 'Please enter the order value.',
                min: { value: 1, message: 'Please enter a valid order value.' },
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label md={4}>Supporting documents</Label>

          <Col md={8}>
            <FormGroupFile 
              name="sales_invoice_files"
              fileType="sales_invoice"
              control={control}
              controlRules={{ required: 'Please attach the sales invoice.' }}
              disabled={disabled}
              defaultValue={defaultFilesValues.sales_invoice_files}
              labelContent={() => <>Sales invoice <span className="text-danger">*</span></>}
              formTextContent={() => <>Upload a copy of your sales invoice (or equivalent), showing payment details and the order number.</>}
            />

            <FormGroupFile 
              name="correspondence_files"
              fileType="correspondence"
              control={control}
              controlRules={{ required: 'Please attach the correspondence.' }}
              disabled={disabled}
              defaultValue={defaultFilesValues.correspondence_files}
              labelContent={() => <>Correspondence <span className="text-danger">*</span></>}
              formTextContent={() => <>Provide a dated copy/screenshot of any written communication you have had about the missing or damaged order.</>}
            />

            {/* User must provide images of the problem unless the claim is "lost". */}
            {selectedClaimReason !== 'lost' && (
              <FormGroupFile 
                name="images_files"
                fileType="item_image"
                control={control}
                controlRules={{ required: 'Please provide a photo of the problem you encountered.' }}
                disabled={disabled}
                defaultValue={defaultFilesValues.images_files}
                labelContent={() => <>Item images <span className="text-danger">*</span></>}
                formTextContent={() => <>If applicable, upload photos of the items or any damage.</>}
              />
            )}

            <FormGroupFile 
              name="other_files"
              fileType="file"
              control={control}
              disabled={disabled}
              defaultValue={defaultFilesValues.other_files}
              labelContent={() => <>Other</>}
              formTextContent={() => <>Upload any additional documentation that may help the investigation (e.g., Proof of Delivery).</>}
            />
          </Col>
        </FormGroup>

        <ButtonWithSpinner
          type="submit"
          color="primary" 
          className="w-100"
          loading={isSubmitting}
          disabled={disabled || isSubmitting}
        >
          Submit
        </ButtonWithSpinner>
      </Form>
    </>
  )
}