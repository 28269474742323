import DownloadShippingLabelButton from "./DownloadShippingLabelButton";

const OrderInformationHeader = (props) => {
	let {order_ref, courier_service, tracking_destination} = props.data

	return (
		<div className="order__header">
			<div className="order__courier">
				<div className="order__courier-name">{courier_service}</div>
			</div>
			<div className="order__link">
				<div className="order__reference">
					<span>Reference:</span>
					<strong>{order_ref}</strong>
					<div className="order__destination">Delivery address: <strong>{`${tracking_destination.city}, ${tracking_destination.state}, ${tracking_destination.country}`}</strong></div>
				</div>
				<DownloadShippingLabelButton
					fulfilment={props.fulfilment}
					is_return={props.is_return}
					tracking_number={props.tracking_number}
					getOrderInfo={props.getOrderInfo}
					getting_action_required={props.getting_action_required}
				/>
			</div>
		</div>
	)
}

export default OrderInformationHeader;
