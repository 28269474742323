import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div className={`lds-ellipsis-wrap ${this.props.alt ? 'alt' : ''} ${this.props.light ? 'light' : ''}`}>
        {
          !this.props.alt ?
          <span>Loading</span> :
          null
        }
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

export default Loader;