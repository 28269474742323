import React, { Component } from "react";
import sprite_icon from '../assets/icons/sprite.svg';

export default class OrderStatus extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.today = new Date();
  }

  renderDeliveryDate(fulfilment) {
    if (!!fulfilment?.estimated_delivery_date &&  fulfilment?.tracking_status !== 'delivered') {
      const isEstimatedDeliveryDateOutdated = this.today >= new Date(fulfilment?.estimated_delivery_date);

      if (!isEstimatedDeliveryDateOutdated) {
        return (
          <span className="fulfillment__delivery">
            Estimated Delivery on: <strong>{new Date(fulfilment?.estimated_delivery_date).toDateString()}</strong>
          </span>
        )
      }
    } else if (fulfilment?.delivery_date !== null && fulfilment?.delivery_date !== '') {
      return (
        <span className="fulfillment__delivery">
          <strong>{new Date(fulfilment?.delivery_date).toDateString()}</strong>
        </span>
      )
    } else {
        return null;
    }
  }

  render () {
    const { tracking_status, tracking_status_display } = this.props.fulfilment;
    const actual_delivery_date = this.renderDeliveryDate(this.props.fulfilment);
    let status_line_position;
    let status_tagline;
    let tracking_status_expanded = tracking_status !== '' ? tracking_status : 'pending';
    if (this.props?.orderStatus === 'cancelled' && !tracking_status) {
      tracking_status_expanded = 'cancelled';
    }

    switch(tracking_status) {
      case '':
        status_line_position = 'start';

        if (this.props.orderStatus === 'cancelled') {
          status_tagline = 'This fulfilment has been cancelled.';
        } else {
          status_tagline = 'We are still processing this fulfilment, please come back later. If the fulfilment is still processing after 24 hours, please contact support';
        }

        break;
      case 'awaiting_collection':
        status_line_position = 'center';
        status_tagline = 'A pickup or drop-off for your parcel has been scheduled and is awaiting collection.';
        break;
      case 'in_transit':
        status_line_position = 'center';
        status_tagline = 'Good to go! Your parcel is in transit to its destination.';
        break;
      case 'lost':
        status_line_position = 'center';
        status_tagline = 'Your parcel has unfortunately been lost and we cannot find it in our network. Reach out to your retailer to open a claim';
        break;
      case 'returned':
        status_line_position = 'end';
        status_tagline = 'The parcel has been returned back to our facilities';
        break;
      case 'shipped':
      case 'delivered':
        status_line_position = 'end';
        status_tagline = 'The parcel was successfully delivered. Yay!';
        break;
      case 'expired':
        status_line_position = 'end';
        status_tagline = 'A parcel status changes to “Expired” if tracking information hasn’t updated for over 30 days';
        break;
      case 'exception':
        status_line_position = 'end';
        status_tagline = 'There is an issue with the parcel that requires attention and we had to stop the shipment.';
        break;
      default:
        status_line_position = 'start';
        status_tagline = 'We’ve got your order! We’re processing it through our network';
        break;
    }

    const defaultStatusDisplay = this.props?.orderStatus === 'cancelled'
      ? 'Cancelled'
      : 'Processing';

    return (
      <div className={`order_status__wrapper status--${tracking_status_expanded}`}>
        <div className="order_status__status">
          <svg className="icon">
            <use xlinkHref={`${sprite_icon}#status-${tracking_status_expanded}`}></use>
          </svg>
          {tracking_status_display || defaultStatusDisplay}

          {actual_delivery_date &&
            <span> on {actual_delivery_date}</span>
          }
        </div>
        <div className="order_status__description">{status_tagline}</div>
          <div className={`order_status__line ${status_line_position}`}>
            <div className="order_status__line-active"></div>
            <div className="order_status__line-pin">
              <svg className="icon">
                <use xlinkHref={`${sprite_icon}#status-${tracking_status_expanded}`}></use>
              </svg>
            </div>
          </div>
      </div>
    )
  }
}
