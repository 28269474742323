import sprite_icon from '../assets/icons/sprite.svg';

import PropTypes from 'prop-types'

export function IconButton({ iconName, onClick, ...buttonProps }) {
  return (
    <button 
      type="button"
      className={`icon ${iconName}`} 
      onClick={onClick}
      {...buttonProps}>
      <svg>
        <use xlinkHref={`${sprite_icon}#${iconName}`}></use>
      </svg>
    </button>
  )
}

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}