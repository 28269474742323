import axios from 'axios';

import { API_TOKEN, API_TRACKING, API_INSURANCE_CLAIMS, API_INSURANCE_FILES_UPLOAD } from "../constants";

export const fetchTrackingPageData = (trackingNumber, trackingNumberParam) => 
  axios.get(`${API_TRACKING}${trackingNumber}/`, {
    params: { tracking_number: trackingNumberParam },
    headers: { "Authorization": API_TOKEN }
  });


/**
 * @typedef InsuranceClaim
 * @type {object}
 * @todo ADD IMPL
 */


/** @param {number} id - Insurance claim ID.
 * 
 *  @returns {Promise<import('axios').AxiosResponse<InsuranceClaim>>}
 */
export const fetchInsurancePageData = (id) => axios.get(`${API_INSURANCE_CLAIMS}${id}/`);

/**
 * @typedef CreateInsuranceClaimPayload
 * @type {object}
 * @property {number} order
 */

/** 
 * Create insurance claim for current order.
 * @param {CreateInsuranceClaimPayload} payload - {@link CreateInsuranceClaimPayload}
 * @returns {Promise<import('axios').AxiosResponse.<{ id: number, order: number }>>}
 */
export function createInsuranceClaim(payload) {
  return axios.post(API_INSURANCE_CLAIMS, payload);
};

/**
 * @typedef UpdateInsuranceClaimPayload
 * @type {object}
 * @property {number} order
 * @property {string} customer_name
 * @property {string} claim_reason
 * @property {string} description
 * @property {string} date_of_issue
 * @property {number} value_of_order
 * @property {Array.<{ order_item: number }>} affected_items
 * @property {Array.<{ file: string, file_type: string }>} files
 */

/** 
 * Create insurance claim for current order.
 * @param {UpdateInsuranceClaimPayload} payload - {@link UpdateInsuranceClaimPayload}  
 * @param {number} id - Insurance claim ID
 * @returns {Promise<import('axios').AxiosResponse<InsuranceClaim>}
 */
export function updateInsuranceClaim(payload, id) {
  return axios.patch(`${API_INSURANCE_CLAIMS}${id}/`, payload);
};

/** 
 * Upload file to dedicated S3 storage for insurance claims.
 * @param {...File} files - Files to upload
 * @returns {Promise<Array.<{ name: string, data: PromiseSettledResult.<string> }>>} List of uploaded files
 */
export async function uploadFiles(...files) {
  const results = await Promise.allSettled(files.map(uploadInsuranceFile));

  return files.map((file, idx) => ({ name: file.name, data: results[idx] }));
}

/** 
 * Upload file to dedicated S3 storage for insurance claims.
 * @param {File} file - File to upload
 * @returns {Promise<string>} URL of the uploaded file
 */
export async function uploadInsuranceFile(file) {
  const { data: s3Data } = await getInsuranceFileUploadParams(file);  
  
  await uploadFileToS3(file, s3Data);

  return `${s3Data.url}${s3Data.fields.key}`;
}

/** 
 * @typedef S3Data
 * @type {object}
 * @property {string} url - URL of S3 storage
 * @property {S3DataFields} fields - Metadata of uploaded file
 */

/**
 * @typedef S3DataFields
 * @type {object}
 * @property {string} key
 * @property {string} acl
 * @property {string} "Content-Type"
 * @property {string} AWSAccessKeyId
 * @property {string} policy
 * @property {string} signature
 */

/** Get upload params for given insurance file.
 * @param {File} file - File to upload
 * @returns {Promise<AxiosResponse<S3Data>>} Upload params
 */
function getInsuranceFileUploadParams(file) {
  return axios.get(API_INSURANCE_FILES_UPLOAD, {
    params: {
      dest_key: 'insurance_claim_files',
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
    },
  });
}

/** Upload file to S3 storage.
 * @param {File} file - File to upload
 * @param {S3Data} s3Data - Upload params
 * @returns {Promise<AxiosResponse<void>>}
 */
function uploadFileToS3(file, s3Data) {
  const data = new FormData();

  for (const [key, value] of Object.entries(s3Data.fields)) {
    data.append(key, value);
  }

  data.append('file', file);

  return axios.post(s3Data.url, data);
}