import { Component } from "react";
import TrackingEventsList from "./TrackingEventsList";
import OrderStatus from './OrderStatus';

export default class Fulfilment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      nested_modal: false
    }
  }

  renderTrackingList (no_events_message, fulfilment) {
    return (
      this.props.fulfilment?.tracking_event?.events.length > 0 &&
      <div className="fulfillment__tracking mobile">
        <TrackingEventsList
          tracking_status={this.props.fulfilment?.tracking_status}
          tracking_status_display={this.props.fulfilment?.tracking_status_display}
          tracking_events={this.props.fulfilment?.tracking_event?.events}
          no_events_message={no_events_message}
          fulfilment={fulfilment}
        />
      </div>
    )
  }

  render() {
    const { fulfilment, order } = this.props
    let no_events_message = "There is no tracking information yet"

    return (
      <div className="fulfillment">
        <OrderStatus fulfilment={fulfilment} orderStatus={order.status} />
        {
            this.renderTrackingList(no_events_message, fulfilment)
          }
        <div className="fulfillment__content">
          <div className="fulfillment__data">
            {
              fulfilment?.tracking_number && fulfilment?.tracking_url ?
                <div className="fulfillment__tracking-number_wrapper">
                  <img className="courier-logo" src={fulfilment?.shipping_account?.courier?.logo} alt={fulfilment?.shipping_account?.name}/>
                  <span>Track with courier</span>
                  <a href={fulfilment.tracking_url}
                    target='_blank'
                    rel='noreferrer noopener'
                    className="fulfillment__tracking-number__url">
                      {fulfilment.tracking_number}
                  </a>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    )
  }
}
