import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ClientThemeContext } from '../constants/themes';
import sprite_icon from '../assets/icons/sprite.svg';
import DOMPurify from 'dompurify';

const redirectToCreatedReturn = (return_order_ref) => {
  window.location.assign(`${window.location.origin}/${return_order_ref}?tracking_number=`)
}

const NestedModal = (props) => {
  const { theme } = useContext(ClientThemeContext);

  const renderActionRequiredText = () => {
    let clean = DOMPurify.sanitize(theme?.config?.fail_to_create_label_text)

    return (
      <Modal
        isOpen={props.nestedModal}
        toggle={props.toggleNested}
        onClosed={props.closeAll ? props.toggle : undefined}
        className='nested-modal'
        >
        <ModalHeader className='error'>
          <svg className="icon">
            <use xlinkHref={`${sprite_icon}#icon-error`}></use>
          </svg>
          Order couldn't be fulfilled.

          <button className='icon close' onClick={props.toggleNested}></button>
        </ModalHeader>
        <ModalBody className="error-text">
          {
            theme?.config?.fail_to_create_label_text ?
            <div dangerouslySetInnerHTML={{__html: clean}} />
            :
            <div>
              <p>Error appeared during the shipping label creation.</p>
              <p>Check the initial order or contact the support, please.</p>
              <p>Thanks for your patience!</p>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <div className='buttons-wrapper'>
            <span className='footnote'>*you can close this window</span>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  const renderSuccessText = () => {
    let clean = DOMPurify.sanitize(theme?.config?.success_text)

    return (
      <Modal
        isOpen={props.nestedModal}
        toggle={props.toggleNested}
        onClosed={props.closeAll ? props.toggle : undefined}
        className='nested-modal'
        >
        <ModalHeader className='success'>
          <svg className="icon">
            <use xlinkHref={`${sprite_icon}#icon-tick`}></use>
          </svg>
          We received your return request.
        </ModalHeader>
        <ModalBody className="success-text">
          {
            theme?.config?.success_text ?
            <div dangerouslySetInnerHTML={{__html: clean}} />
            :
            <div>
              <p><b>We have created</b> a <a href={`${window.location.origin}/${props.return_order_ref}?tracking_number=`} target='_blank' rel='noreferrer noopener'>return order</a> <b>with a new</b><br/> <a href={props.created_shipping_label} target='_blank' rel='noreferrer noopener'>generated shipping label</a> for you.</p>
              <p>And the confirmation email with the shipping label has been sent to <b>{props.return_email}</b></p>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <div className='buttons-wrapper'>
            <button className="btn btn-primary btn-full" onClick={() => redirectToCreatedReturn(props.return_order_ref)}>See created return</button>
            <button className="btn btn-outline btn-full" onClick={() => window.location.reload()}>
              <i className="arrow left"></i>Back to origin order
            </button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  const renderTimeoutedText = () => {
    return (
    <Modal
      isOpen={props.nestedModal}
      toggle={props.toggleNested}
      onClosed={props.closeAll ? props.toggle : undefined}
      className='nested-modal'
      >
      <ModalHeader className='warning'>
        <svg className="icon">
          <use xlinkHref={`${sprite_icon}#icon-wait`}></use>
        </svg>
        We need a bit more time!
      </ModalHeader>
      <ModalBody className="success-text">
        <div>
          <p>Creating your shipping label is taking longer than expected.</p>
          <p>We will send it to your email <b>{props.return_email}</b> when it's ready.</p>
          {
            props.check_for_updates ?
            <p>In the meantime, you can click on the <b>check for updates</b> button below to get the updates of status for this order.</p>
            :
            <p>In the meantime, you can see the status of your newly created return order on this <a href={`${window.location.origin}/${props.return_order_ref}?tracking_number=`}>page</a></p>
          }
          {
            props.check_for_updates &&
            <p>If it takes more then 20 minutes contact the support, please</p>
          }
          <p>Thanks for your patience!</p>
        </div>
      </ModalBody>
      <ModalFooter>
        {
          props.check_for_updates ?
          <div className='buttons-wrapper'>
            <button className="btn btn-primary btn-full" onClick={() => checkForUpdates()}>Check for updates</button>
            <span className='footnote'>*modal will be closed after clicking on button</span>
          </div>
          :
          <div className='buttons-wrapper'>
            <button className="btn btn-primary btn-full" onClick={() => redirectToCreatedReturn(props.return_order_ref)}>See created return</button>
            <button className="btn btn-outline btn-full" onClick={() => window.location.reload()}>
              <i className="arrow left"></i>Back to origin order
            </button>
          </div>
        }
      </ModalFooter>
    </Modal>
    )
  }

  const renderModal = () => {
    if (props.getting_action_required) {
      return renderActionRequiredText()
    } else if (props.getting_label_timeouted) {
      return renderTimeoutedText()
    } else {
      return renderSuccessText()
    }
  }

  const checkForUpdates = () => {
    props.getOrderInfo()
    props.toggleNested()
  }

  return (
    renderModal()
  )
}

export default NestedModal;
