import React, {Component} from "react";
import {Alert} from "reactstrap";
import { Helmet } from 'react-helmet'

import { ClientThemeContext } from '../constants/themes';
import { fetchTrackingPageData } from '../utils/api';

import OrderCard from "./OrderCard";
import Loader from "./Loader";
import { Map } from "./Map";
import { Empty } from "./Empty";


// TODO: rewrite everything with functional components
class Home extends Component {
  static contextType = ClientThemeContext;

	constructor(props) {
		super(props);

    this.state = {
      orders: [],
      order: {},
      active_fulfilment: {},
      handlingError: {
        isError: false,
        message: ''
      },
      loading: true,
    }
  }

  componentDidMount() {
    if (this.props.tracking_number !== '') {
      this.getOrderInfo()
    }
  }

  showError() {
    setTimeout(() => {
      this.setState({
        handlingError: {
          isError: false,
          message: ''
        }
      })
    }, 4000)

    return (
      <Alert color="danger">
        {this.state.handlingError.message}
      </Alert>
    )
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  getOrderInfo = () => {
    // TODO: load this data in root component
    fetchTrackingPageData(this.props.tracking_number, this.props.tracking_number_param)
      .then((res) => {
        /** Use fulfilment of return order if present, otherwise fallback to first order. */
        const activeOrder = res.data?.orders?.find(el => el.is_return) ?? res.data?.orders?.[0];
        const activeFulfilment = activeOrder?.fulfillments?.[0];

        this.setState({
          orders: res.data?.orders,
          order: res.data?.orders[0],
          active_fulfilment: activeFulfilment,
        });

        this.context.updateTheme(res.data?.preferences);
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            handlingError: {
              isError: true,
              message: error.response.status === 404 ? 'Sorry, your tracking number is incorrect' : error.message
            },
          })
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  renderOrders() {
    const ordersList = this.state.orders.map(order => {
      return (
        <OrderCard
          key={order.id}
          loading={false}
          order={order} // need for change active order
          order_id={order.id}
          customer_order_ref={order.customer_reference}
          order_ref={order.reference}
          tracking_number={this.props.tracking_number}
          tracking_number_param={this.props.tracking_number_param}
          is_return_action_available={order.is_return_action_available}
          is_return={order.is_return}
          fulfillments={order.fulfillments}
          destination={order.destination_address}
          order_items={order.order_items}
          active_order={this.state.order.id === order.id ? true : false}
          getActiveFulfilment={this.getActiveFulfilment}
          active_fulfilment={this.state.active_fulfilment}
          getOrderInfo={this.getOrderInfo}
          getting_action_required={order.status === 'action_required' ? true : false}
          includesReturnOrder={this.state.orders.some(el => el.is_return)}
          toastsContext={this.props.toastsContext}
        />
      )
    })
    // sort the output as original order > related returns > another original order > related returns
    return ordersList.sort((a, b) => {
      if (a.props.order.customer_reference > b.props.order.customer_reference) {
        return 1;
      } else if (a.props.order.customer_reference < b.props.order.customer_reference) {
        return -1;
      } else {
        return 0
      }
    });
  }

  getActiveFulfilment = (fulfilment) => {
    this.setState({active_fulfilment: fulfilment})
  }

  renderEmpty() {
    return (
      <Empty 
        orders={this.state.orders} 
        trackingNumber={this.props.tracking_number} 
        trackingNumberParam={this.props.trackingNumberParam} 
      />
    )
  }

  renderContent() {
    const orders = this.state.orders;
    const hasMap = this.state.order.reference.includes('GVA');
    const vesselTracking = this.state.active_fulfilment?.tracking_event?.vessel_tracking;

    return (
      orders && orders.length > 0 &&
      <div className=' main-content'>
        <div className="order__list-wrap">
          <div className='order__list'>
            {
              this.renderOrders()
            }
          </div>
        </div>
        { hasMap && !!vesselTracking && (
          <Map
            order={this.state.order}
            vesselTracking={vesselTracking}
          />
        )}
      </div>
    )
  }

  renderDispatcher() {
    if (this.state.loading && !this.state.handlingError.isError && this.props.tracking_number) {
      return <Loader />
    } else if (!this.state.handlingError.isError && this.props.tracking_number && this.state.orders.length !== 0) {
      return this.renderContent()
    } else {
      return this.renderEmpty()
    }
  }

  render() {
    const { config } = this.context.theme;

    const companyName = config.company_name || config.name;

    const pageTitle = this.props.tracking_number 
      ? `${companyName} | Tracking number: ${this.props.tracking_number}` 
      : config.title;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={`Tracking | ${companyName}`} />
        </Helmet>

        {this.renderDispatcher()}
      </>
    );
  }
}

export default Home;
