import {Component} from "react";
import {Input} from "reactstrap";
import sprite_icon from '../assets/icons/sprite.svg';


class ProductsList extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  getOrderItems() {
    let productItems = this.props.order_items.map(item => {
      return (
        <div className="return-product" key={item.id}>
          <div className="return-product__image">
            {item.image ?
              <img className="image" src={item.image} alt={item.title}/>
              :
              <div className="image image--empty"></div>
            }
          </div>
          <div className="return-product__name">
            <div className="return-product__title">{item.title}</div>
            <div className="return-product__sku">{item.sku}</div>
          </div>
          <div className="return-product__quantity">
            <label>Quantity</label>
            <Input type="number"
                   name="quantitySelect"
                   id="quantitySelect"
                   min="1"
                   placeholder={item.quantity}
                   value={item.quantity}
                   onChange={(e) => this.props.onChangeQuantity(item.id, e.target.value)}
            />
          </div>
          {
            this.props.order_items?.length > 0 &&
            (<div className="return-product__remove" onClick={() => this.props.deleteOrderItem(item.id)}>
              <button className='icon icon-delete'>
                <svg>
                  <use xlinkHref={`${sprite_icon}#icon-delete`}></use>
                </svg>
              </button>
            </div>)
          }
        </div>
      )
    })

    return productItems;
  }

  render() {
    return (
      <div>
            {this.getOrderItems()}
            {
              this.props.items_were_deleted &&
              <div className="return-product__removed">
                It looks like some items were deleted.
                <button className='return-product__recover_btn' onClick={() => this.props.dropOrderItemState()}>Recover them here</button>
              </div>
            }
      </div>
    )
  }

}

export default ProductsList;