import React, { Component } from "react";
import sprite_icon from '../assets/icons/sprite.svg';
import NestedModal from "./NestedModal";

class DownloadShippingLabelButton extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      nested_modal: false
    }
  }

  toggleNested = () => {
    this.setState({nested_modal: !this.state.nested_modal})
  }

  renderButton() {
    const { fulfilment, is_return } = this.props;

    if( is_return) {
      if (fulfilment.shipping_label !== ""  && fulfilment.shipping_label !== null ) {
        return (
          <div className="fulfillment__shipping-label">
            <a target="_blank" rel="noreferrer" href={fulfilment.shipping_label}>
              <svg className="icon">
                <use xlinkHref={`${sprite_icon}#icon-print`}></use>
              </svg>
              Print return label
            </a>
          </div>
        )
      } else {
        return (
          <div className="fulfillment__shipping-label">
            <button className='shipping-label-btn' onClick={this.toggleNested}>
              <svg className="icon">
                <use xlinkHref={`${sprite_icon}#icon-print`}></use>
              </svg>
              Print return label
            </button>
            <NestedModal
              nestedModal={this.state.nested_modal}
              toggleNested={this.toggleNested}
              tracking_number={this.props.tracking_number}
              getting_label_timeouted={true}
              check_for_updates={true}
              getOrderInfo={this.props.getOrderInfo}
              getting_action_required={this.props.getting_action_required}
            />
          </div>
        )
      }
    } else {
      return null
    }
  }

  render () {
    return this.renderButton()
  }
}

export default DownloadShippingLabelButton;