import { useContext } from "react";

import Home from "../components/Home";


import { ToastsContext } from "../utils/toasts.context";
import { useTrackingPageParams } from '../utils/hooks';

export function HomePage() {
  const toastsContext = useContext(ToastsContext);

  const { trackingNumber, trackingNumberParam } = useTrackingPageParams();

  return (
    <Home
      tracking_number={trackingNumber}
      tracking_number_param={trackingNumberParam}
      // Pass context as prop because legacy class components doesn't support multiple contexts.
      toastsContext={toastsContext}
    />
  )
}