import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

export function ButtonWithSpinner({ children, loading, className = '', ...buttonProps }) {
  return (
    <Button 
      type="submit"
      className={`btn-with-spinner ${className}`}
      disabled={loading}
      {...buttonProps}
    >
      {children}

      {loading && (
        <div className="spinner-container">
          <Spinner size="sm">Loading...</Spinner>
        </div>
      )}
    </Button>
  )
}

ButtonWithSpinner.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
}
