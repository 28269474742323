import { createContext } from "react";

export const themes = {
  default: {
    "name": "default",
    "config": {
      "links": {
          "site": "",
          "custom_links": []
      },
      "title": "",
      "favicon": "",
      "return_text": "Details and return",
      "font_color": "",
      "theme_color": "",
      "company_logo": "",
      "company_name": "",
      "success_text": ""
    },
  }
};

export const ClientThemeContext = createContext({ 
  theme: themes.default, 
  updateTheme: (_preferences) => {},
 });