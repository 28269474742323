export const INSURANCE_CLAIM_EDITABLE_STATES = ['draft', 'awaiting_approval'];

/**
 * Check whether the form should be disabled based on its state.
 * 
 * @param {import('../utils/api').InsuranceClaim} insuranceClaim 
 * 
 * @returns {boolean}
 */
export const checkIfFormShouldBeDisabled = (insuranceClaim) => 
  !INSURANCE_CLAIM_EDITABLE_STATES.includes(insuranceClaim?.state);