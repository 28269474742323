import PropTypes from 'prop-types';

export function EmptyWrapper({ children }) {
  return (
    <div className="empty-page-wrap">
      <div className="empty-page-content">
        {children}
      </div>
    </div>
  )
}

export function Empty({ orders = [], trackingNumber, trackingNumberParam }) {
  return (
    <EmptyWrapper>
      {trackingNumber && orders.length === 0 ? (
        <div>
          <h2>Whoops, seems there is no such tracking number - <em>{trackingNumberParam}</em> or such order - <em>{trackingNumber}</em></h2>
          <p>Check if your entered number is correct or try another one. Thank you!</p>
        </div>
        ) : (
        <div>
          <h2>Tracking and returns made easy!</h2>
          <p>Track your order or return your item with our carbon neutral returns service.</p>
        </div>
      )}
    </EmptyWrapper>
  );
}

Empty.propTypes = {
  orders: PropTypes.array,
  trackingNumber: PropTypes.string,
  trackingNumberParam: PropTypes.string,
}
