import React, { useContext } from "react";
import { CDN_HOST } from "../constants";
import { ClientThemeContext } from "../constants/themes.js";

const Header = () => {
  const { theme } = useContext(ClientThemeContext);

  const logo_url_raw = theme?.config?.company_logo;
  const logo_url = logo_url_raw?.includes('https') ? logo_url_raw : CDN_HOST + logo_url_raw;

  return (
    <header className="header" >
      <div className="header-content">
        <div className='header__logo'>
        {
          theme?.config?.company_logo &&
          <a href={theme?.config?.links?.site} target="_blank" rel="noreferrer">
            <img src={logo_url} alt={theme?.config?.company_name || 'Company logo'} />
          </a>
        }
        </div>
      </div>
    </header>
  );
}

export default Header;
