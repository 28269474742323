import { useContext } from "react";
import {Container} from "reactstrap";
import { ClientThemeContext } from "../constants/themes.js";

const renderLinks = (theme) => {
  let result = [];
  theme?.config?.links?.custom_links.forEach((link, i) => {
    result.push(
      <div className="footer__link" key={i}>
        {
          link.url ? (<a href={link.url}>{link.name}</a>) : (<span>{link.name}</span>)
        }
      </div>
    )
  });
  return result;
}

const Footer = () => {
  const { theme } = useContext(ClientThemeContext)

  return (
    <Container fluid={true} className='sticky-footer footer'>
      <div className="footer-content">
        <div className="footer__logo">
          <div className="copyright">Powered by <a href="https://borderless360.com"><img src="/assets/images/logo-b360.png" height="10" alt="" /></a></div>
        </div>
        <div className="footer__links">
          {
            theme.config?.links &&
            renderLinks(theme)
          }
        </div>
        <div className="footer__cnc"><img src="/assets/images/logo-cnc.png" height="41" alt="" /></div>
      </div>
    </Container>
  )
}

export default Footer;
