import React from "react";
import Fulfilment from "./Fulfilment";
import { isEqual } from "lodash";


const Fulfillments = (props) => {
  if (props.fulfillments?.length > 0) {
    const fulfillments_list = props.fulfillments.map((fulfilment, i) => {
      let is_active = false;

      if (isEqual(fulfilment, props.active_fulfilment)) {
        is_active = true
      }

      return (
        <Fulfilment
          order={props.order}
          fulfilment={fulfilment}
          key={i}
          tracking_number={props.tracking_number}
          is_active={is_active}/>
      )
    })

    return (
      <div className='fulfillment-list'>{ fulfillments_list }</div>
    )
  } else {
    return (
      <div className='fulfillment-list'>There is no shipping information yet.</div>
    )
  }
}

export default Fulfillments;
