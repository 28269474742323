import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import PropTypes from 'prop-types';

export function ToastsContainer({ toasts = [] }) {
  return (
    <div className="toasts-contanier">
      {toasts.map(({ headerProps, bodyProps }, idx) => (
        <Toast key={idx} fade>
          {headerProps && <ToastHeader {...headerProps} />}
          <ToastBody {...bodyProps} />
        </Toast>
      ))}
    </div>
  )
}

ToastsContainer.propTypes = {
  toasts: PropTypes.array.isRequired,
}
