export const CDN_HOST = process.env.REACT_APP_CDN_HOST;
export const API_TOKEN = process.env.REACT_APP_TOKEN;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const GOOGLE_MAPS_API = process.env.REACT_APP_GOOGLE_MAPS_API;

export const SERVER_URL = process.env.REACT_APP_API_URL;
export const API_TRACKING = `${SERVER_URL}api/v1/customer_api/tracking_and_return/`;

// TODO: add same for the client_api/ endpoint
export const API_INSURANCE_CLAIMS = `${SERVER_URL}api/v1/public_api/insurance_claims/`;
export const API_INSURANCE_FILES_UPLOAD = `${SERVER_URL}api/v1/public_api/upload/get_insurance_file_upload_params/`;